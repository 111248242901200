import * as React from "react";
import Layout from "../components/Layout";
import ThreePrices from "../components/pricing/ThreePrices";
import { createSeoData } from "../utils/page";

export default function PricingPage() {
  const oneLessonFeatures = [
    "Try a lesson and decide whether to continue",
    "Get help with a specific language question",
    "Work with a professional coach before the big exam",
  ];
  const tenLessonFeatures = [
    "Develop a relationship with a tutor",
    "Meet specific language goals",
    "Complete homework and get feedback",
    "Work toward advancing levels in fluency",
    "Produce more complex written work",
    "Prepare for travel to a foreign country",
  ];
  const fiftyLessonsFeatures = [
    "Address long term language learning goals",
    "Get the best per lesson rates",
    "Develop deep understanding of the target culture",
  ];

  const seo = createSeoData({ title: "Pricing" });

  return (
    <Layout seo={seo}>
      <ThreePrices
        oneLessonFeatures={oneLessonFeatures}
        tenLessonFeatures={tenLessonFeatures}
        fiftyLessonsFeatures={fiftyLessonsFeatures}
      />
    </Layout>
  );
}
